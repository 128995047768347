<template>
 <sdPageHeader title="Relatie bewerken">
 </sdPageHeader>
 <Main>
  <Relation
   :show-card-save-button="true"
   :show-global-save-button="false"
   :show-orders="true"
   type="EDIT"
  ></Relation>
 </Main>
</template>

<script>
import {defineComponent} from 'vue';
import Relation from "../../components/relation/Relation.vue";
import {Main} from "../styled";

export default defineComponent({
 name: 'RelationEdit',
 components: {
  Relation,
  Main,
 },
});
</script>
