<template>
 <div>
  <sdPageHeader title="Gebied">
  </sdPageHeader>
  <Main>
   <div class="user-info-form">
    <BasicFormWrapper class="mb-25">
     <sdCards title="Details">
      <a-skeleton v-if="loadingArea" active/>
      <a-form v-else :model="form" :rules="rules" layout="vertical" @finish="updateArea">
       <a-row :gutter="30">
        <a-col :sm="6" :xs="24" class="mb-25">
         <a-form-item label="ID" name="id">
          <a-input v-model:value="form.id" :disabled="true" placeholder=""/>
         </a-form-item>
        </a-col>
        <a-col :sm="6" :xs="24" class="mb-25">
         <a-form-item label="Naam" name="name">
          <a-input v-model:value="form.name" placeholder=""/>
         </a-form-item>
        </a-col>
        <a-col :sm="6" :xs="24" class="mb-25">
         <a-form-item label="Postcodegebied vanaf" name="postalCodeFrom">
          <a-input-number v-model:value="form.postalCodeFrom" :max="9999" :min="1000" class="w-100" placeholder=""/>
         </a-form-item>
        </a-col>
        <a-col :sm="6" :xs="24" class="mb-25">
         <a-form-item label="Postcodegebied tot" name="postalCodeTill">
          <a-input-number v-model:value="form.postalCodeTill" :max="9999" :min="1000" class="w-100" placeholder=""/>
         </a-form-item>
        </a-col>
       </a-row>
       <a-row>
        <a-col :sm="12" :xs="24">
         <div class="sDash_form-action">
          <sdButton v-if="$can('update', 'sender')" :disabled="loadingPatchSender"
                    :loading="loadingPatchSender && (clickedButton === 2)" class="btn-signin"
                    type="primary"> Opslaan
          </sdButton>
         </div>
        </a-col>
       </a-row>
      </a-form>
     </sdCards>
    </BasicFormWrapper>
   </div>
   <div class="user-info-form">
    <BasicFormWrapper class="mb-25">
     <sdCards>
      <div class="ant-card-head">
       <div class="ant-card-head-wrapper">
        <div class="ant-card-head-title">
         <a-row :gutter="16">
          <a-col :span="12">
           Tijdsblokken
          </a-col>
          <a-col :span="12" style="display: flex;justify-content: end">
           <TimeBlockAdd/>
          </a-col>
         </a-row>
        </div>
       </div>
      </div>
      <LeadAreaTimeBlocksTable/>
     </sdCards>
    </BasicFormWrapper>
   </div>
   <sdCards>
    <a-row>
     <a-col :sm="12" :xs="24">
      <div class="sDash_form-action">
       <sdButton class="btn-signin" html-type="submit" type="light" @click="$router.go(-1)">
        Terug
       </sdButton>
      </div>
     </a-col>
    </a-row>
   </sdCards>
  </Main>
 </div>
</template>

<script>
import {BasicFormWrapper, Main} from '../styled';
import {computed, defineComponent, onMounted, reactive, ref} from 'vue';
import {useStore} from "vuex";
import {useRoute} from "vue-router";
import {countryOptions} from "../../utility/enums";
import {phoneNumberValidator} from "../../utility/validators";
import LeadAreaTimeBlocksTable from "./component/LeadAreaTimeBlocksTable.vue";
import TimeBlockAdd from "./component/TimeBlockAdd.vue";

export default defineComponent({
 name: 'LeadAreaEdit',
 components: {
  TimeBlockAdd,
  BasicFormWrapper,
  Main,
  LeadAreaTimeBlocksTable,
 },
 setup() {
  const {state, dispatch} = useStore();
  const {params} = useRoute();
  const clickedButton = ref(0);
  const form = reactive(computed(() => state.lead.area));
  const loadingPatchSender = computed(() => state.team.loadingPatchSender);
  const loadingArea = computed(() => state.lead.loadingTimeBlocks);
  const loadingTimeBlocks = computed(() => state.lead.loadingTimeBlocks);

  const rules = {
   senderName: [
    {
     required: true,
     message: 'Dit veld is verplicht',
    },
   ],
   senderEmail: [
    {
     required: true,
     type: "email",
     message: "Vul een valide e-mailadres in",
    },
   ],
   phoneNumber: [
    {
     required: true,
     message: 'Dit veld is verplicht',
    },
    {
     validator: phoneNumberValidator,
    },
   ],
   email: [
    {
     required: true,
     type: "email",
     message: "Vul een valide e-mailadres in",
    },
   ],
   smsOriginator: [
    {
     required: true,
     message: 'Dit veld is verplicht',
    },
   ],
  };

  const updateArea = () => {
   clickedButton.value = 1;
   dispatch('patchSender', {
    id: params.id,
    name: form.value.name,
    senderName: form.value.senderName,
    senderEmail: form.value.senderEmail,
    email: form.value.email,
    phoneNumber: form.value.phoneNumber,
    smsOriginator: form.value.smsOriginator,
   });
  };


  onMounted(async () => {
   await dispatch('getArea', params.id);
   await dispatch('getTimeBlocks', params.id);
  });

  return {
   form,
   rules,
   updateArea,
   loadingPatchSender,
   clickedButton,
   countryOptions,
   loadingTimeBlocks,
   loadingArea,
  };
 },
});
</script>
