<template>
 <TableStyleWrapper>
  <TableWrapper class="table-responsive">
   <a-table
    :columns="leadTableColumns"
    :dataSource="leadTableData"
    :loading="loadingTimeBlocks"
    :pagination="{
          defaultPageSize: 10,
          total: count,
          current: currentPage,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${count} items`,
        }"
    @change="paginationChange"
   />
  </TableWrapper>
 </TableStyleWrapper>
</template>
<script>
import {TableStyleWrapper, TableWrapper} from '../../styled';
import {computed, defineComponent} from 'vue';
import {useStore} from "vuex";
import moment from "moment";

const leadTableColumns = [
 {
  title: 'ID',
  dataIndex: 'id',
  key: 'id',
 },
 {
  title: 'Datum & Tijd',
  dataIndex: 'date',
  key: 'date',
 },
 {
  title: 'Maximale aantal afspraken',
  dataIndex: 'maxAppointments',
  key: 'maxAppointments',
 },
 {
  title: 'Status',
  dataIndex: 'status',
  key: 'status',
 },
];
const LeadAreaTimeBlocksTable = defineComponent({
 name: 'LeadAreaTimeBlocksTable',
 components: {TableStyleWrapper, TableWrapper},
 setup() {
  // const router = useRouter();
  const {state, dispatch} = useStore();
  // const {matched} = useRoute();
  // const {path} = matched[1];
  const timeBlocks = computed(() => state.lead.timeBlocks);
  const loadingTimeBlocks = computed(() => state.lead.loadingTimeBlocks);
  const currentPage = computed(() => state.lead.currentPageTimeBlocks);
  const count = computed(() => state.lead.countTimeBlocks);
  // const {can} = useAbility();
  // const handleDelete = async (id) => {
  //  await dispatch('deleteSender', id);
  //  await dispatch('getSenders', {page: state.lead.currentPage, pageSize: state.lead.pageSize});
  // };
  // const handleEdit = (id) => {
  //  router.push(`${path}/${id}`);
  // };
  const leadTableData = computed(() =>
   timeBlocks.value.map((timeBlock) => {
    const {id, active, date, maxAppointments, timeFrom, timeTill} = timeBlock;

    return {
     key: id,
     id: id,
     date: <span>{timeFrom}-{timeTill} {moment(date).format('DD-MM-YYYY')}</span>,
     maxAppointments: <span>{maxAppointments}</span>,
     status: <span>{active ? 'Actief' : 'Inactief'}</span>,
     // action: (
     //  <div className="table-actions">
     //   {can('view', 'sender') ? (
     //    <sdButton onClick={() => handleEdit(id)} className="btn-icon" to="#" type="default" shape="circle">
     //     <sdFeatherIcons type="edit" size="16"/>
     //    </sdButton>
     //   ) : ('')}
     //   {can('delete', 'sender') ? (
     //    <a-popconfirm
     //     title="Weet je het zeker? U kunt dit niet meer terugdraaien!"
     //     ok-text="Ja"
     //     cancel-text="Nee"
     //     placement="leftTop"
     //     onConfirm={() => handleDelete(id)}
     //    >
     //     <sdButton className="btn-icon" type="default" to="#" shape="circle">
     //      <sdFeatherIcons type="trash-2" size="16"/>
     //     </sdButton>
     //    </a-popconfirm>
     //   ) : ('')}
     //  </div>
     // ),
    };
   }),
  );

  const rowSelection = {
   getCheckboxProps: (record) => ({
    disabled: record.name === 'Disabled sender', // Column configuration not to be checked
    name: record.name,
   }),
  };

  const paginationChange = async (event) => {
   await dispatch('setCurrentPageTimeBlocks', event.current);
   await dispatch('getLeads', {page: event.current, pageSize: state.sender.pageSize});
  }

  return {leadTableColumns, leadTableData, rowSelection, loadingTimeBlocks, paginationChange, count, currentPage};
 },
});

export default LeadAreaTimeBlocksTable;
</script>
